import clsx from 'clsx';
import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  children: React.ReactNode;
  isPrimary?: boolean;
  classNames?: string;
  onClick?: (e: React.MouseEvent) => void;
}

const Button = ({
  type = 'button',
  disabled = false,
  loading = false,
  loadingText,
  children,
  isPrimary,
  classNames = '',
  onClick,
}: ButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        'inline-flex justify-center px-4 py-2 text-sm font-medium border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        isPrimary
          ? 'text-white bg-indigo-600 hover:bg-indigo-700 border-transparent'
          : 'text-gray-700 hover:bg-gray-50 border-gray-300',
        disabled && isPrimary && 'bg-indigo-400 hover:bg-indigo-400 cursor-default',
        disabled && !isPrimary && 'bg-gray-100 hover:bg-gray-100 cursor-default',
        classNames,
      )}
    >
      {loadingText && loading ? loadingText : children}
    </button>
  );
};

export default Button;
