export const TEXT_SHORT = 'Question::TextShort';
export const TEXT_LONG = 'Question::TextLong';
export const SELECT_SINGULAR = 'Question::SelectSingular';
export const SELECT_MULTIPLE = 'Question::SelectMultiple';

export const questionTypes = [
  { type: TEXT_SHORT, prettyType: 'Text' },
  { type: TEXT_LONG, prettyType: 'Text (Long)' },
  { type: SELECT_SINGULAR, prettyType: 'Select' },
  { type: SELECT_MULTIPLE, prettyType: 'Select (Multiple)' },
];

export const isSelectQuestion = (questionType: string) => {
  return [SELECT_SINGULAR, SELECT_MULTIPLE].includes(questionType);
};

export const apiFetch = async ({
  url,
  method = 'GET',
  headers = {},
  body = {},
}: {
  url: string;
  method?: string;
  headers?: any;
  body?: any;
}) => {
  const csrfToken = (document.querySelector('[name=csrf-token]') as HTMLMetaElement)?.content || '';
  return await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken,
      ...headers,
    },
    body,
  }).then((res: any) => res.json());
};

export const isEmailValid = (email: string) =>
  email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);

export const formatAnswerValue = (
  rawValue: number | string | string[],
  questionType: string,
): string | string[] => {
  const isSelectMultipleAnswer = questionType === SELECT_MULTIPLE;
  const stringRawValue = rawValue?.toString?.();
  if (!isSelectMultipleAnswer) return stringRawValue;

  return Array.isArray(rawValue) ? rawValue : JSON.parse(stringRawValue || '[]');
};
