import { Inertia } from '@inertiajs/inertia';
import { useForm } from '@inertiajs/inertia-react';
import React, { useState } from 'react';

import Button from '@/components/Button';
import Input from '@/components/Input';
import Modal from '@/components/Modal';
import { isEmailValid } from '@/utils/index';

interface LogInModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

const LogInModal = ({ isModalOpen, closeModal }: LogInModalProps) => {
  const { data, setData } = useForm({ user: { email: '' } });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    Inertia.post('/users/sign_in', data, {
      onSuccess: closeModal,
      onStart: () => setIsSubmitting(true),
      onFinish: () => setIsSubmitting(false),
    });
  };

  return (
    <Modal isModalOpen={isModalOpen} closeModal={closeModal} title={'Log In'} size="xl">
      <form onSubmit={submit}>
        <div className="my-3">
          <Input
            name="email"
            type="email"
            id="email"
            label="Email address"
            value={data.user.email}
            placeholder="Enter email address"
            required
            onChange={(event) => setData('user', { email: event.currentTarget.value })}
          />
        </div>
        <div className="mt-5 flex justify-end">
          <div>
            <Button type="button" onClick={closeModal} classNames="mr-2">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting || !isEmailValid(data?.user?.email || '')}
              loading={isSubmitting}
              loadingText="Submitting…"
              isPrimary
            >
              Send Log In Link
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default LogInModal;
