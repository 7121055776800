import React, { useState, Fragment } from 'react';
import { Inertia } from '@inertiajs/inertia';
import { usePage, Link } from '@inertiajs/inertia-react';
import { Listbox, Popover } from '@headlessui/react';
import clsx from 'clsx';
import LogInModal from '@/Pages/Public/Sections/LogInModal';

const Navbar = () => {
  const page = usePage();
  const {
    auth,
    community,
    communities = [],
  } = page.props as {
    auth?: { user: Partial<schema.User> };
    community?: Partial<schema.Community>;
    communities?: Partial<schema.Community>[];
  };
  const currentUser = auth?.user;
  const currentCommunity = community || communities[0];
  const isManager = currentUser?.organizations
    ?.map((o) => o.id)
    ?.includes(currentCommunity?.organizationId || 0);

  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const handleCommunityChange = (newCommunity: Partial<schema.Community>) => {
    const nextPath = `/communities/${newCommunity.slug}/profile`;
    Inertia.get(nextPath);
  };

  return (
    <>
      <nav className="flex items-center justify-between flex-wrap py-4 lg:px-10 px-3 text-gray-700 bg-white border-b-2">
        {/* Nav header */}
        <div className="flex items-center flex-no-shrink mr-6 text-xl">
          <Link
            href={currentCommunity?.id ? `/communities/${currentCommunity?.slug}/profile` : '/'}
            className="font-semibold tracking-wide"
          >
            Intro
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-700">
              Send
            </span>
          </Link>
        </div>
        {/* Menu button on small screens */}
        <div className="block lg:hidden">
          <button
            onClick={() => setIsNavMenuOpen((prevState) => !prevState)}
            className="flex items-center px-3 py-2 border rounded text-gray-600 border-indigo-800 hover:text-teal-700 hover:border-teal-700"
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        {/* Nav links */}
        <div
          className={clsx('w-full block lg:flex-1 lg:flex items-center text-right lg:text-left', {
            block: isNavMenuOpen,
            hidden: !isNavMenuOpen,
          })}
        >
          {currentUser?.id ? (
            <div>
              <Link
                href={`/communities/${currentCommunity?.slug}/profile`}
                className="block mt-4 lg:inline-block tracking-wide font-medium lg:mt-0 lg:mr-4 mb-2 lg:mb-0"
              >
                Profile
              </Link>
            </div>
          ) : (
            <div>
              <Link
                href="/#about"
                className="block mt-4 lg:inline-block tracking-wide font-medium lg:mt-0 lg:mr-4 mb-2 lg:mb-0"
              >
                About
              </Link>
            </div>
          )}

          {isManager ? (
            <div>
              <Link
                href={`/communities/${currentCommunity?.slug}/manage`}
                className="block mt-4 lg:inline-block tracking-wide font-medium lg:mt-0 lg:mr-4 mb-2 lg:mb-0"
              >
                Manage
              </Link>
            </div>
          ) : null}

          {currentUser?.id ? (
            <>
              <div className="w-full flex relative justify-end mt-4 lg:mt-0 text-right lg:text-left text-sm items-center">
                {communities.length && (
                  <Listbox
                    value={communities.find((c) => c.id === currentCommunity?.id)}
                    onChange={handleCommunityChange}
                  >
                    <Listbox.Button className="flex justify-center cursor-pointer rounded-lg border border-gray-100 bg-white py-2 px-4 lg:mr-5 w-40 shadow focus:outline-none focus-visible:border-indigo-500 sm:text-sm">
                      <span className="block truncate text-center">{currentCommunity?.name}</span>
                    </Listbox.Button>
                    <Listbox.Options className="absolute top-7 -right-2 w-full border border-gray-100 rounded-md shadow-lg md:w-64 m-4">
                      <div className="flex flex-col py-1.5 bg-white rounded-md shadow">
                        {communities.map((c) => (
                          <Listbox.Option key={c.id} value={c} as={Fragment}>
                            {({ selected, active }) => (
                              <li
                                className={clsx('py-2 px-5 cursor-pointer', {
                                  'bg-gray-100': active,
                                })}
                              >
                                <div className={clsx({ 'pl-5': !selected })}>
                                  {selected && <i className="fa-solid fa-check mr-2"></i>}
                                  {c.name}
                                </div>
                              </li>
                            )}
                          </Listbox.Option>
                        ))}
                      </div>
                    </Listbox.Options>
                  </Listbox>
                )}
                <Popover>
                  <Popover.Button className="hidden lg:flex">
                    <div>
                      <i className="fa-solid fa-circle-user fa-2xl"></i>
                    </div>
                  </Popover.Button>
                  <Popover.Panel>
                    <div className="absolute -right-5 top-11 w-full border border-gray-100 rounded-md shadow-lg md:w-64">
                      <div className="flex flex-col p-5 bg-white rounded-md shadow">
                        <div className="text-sm mb-5">{currentUser.email}</div>
                        <div>
                          <a
                            href="/logout"
                            className="py-1.5 px-5 rounded text-sm font-semibold cursor-pointer border border-gray-300 hover:bg-gray-100 w-20"
                          >
                            Log Out
                          </a>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Popover>
              </div>
              <div className={clsx({ hidden: !isNavMenuOpen })}>
                <div className="flex flex-col mt-4">
                  <div className="text-sm mb-5">{currentUser.email}</div>
                  <div>
                    <a
                      href="/logout"
                      className="py-1.5 px-5 rounded text-sm font-semibold cursor-pointer border border-gray-300 hover:bg-gray-100 w-20"
                    >
                      Log Out
                    </a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="w-full block lg:flex lg:flex-row lg:flex-1 mt-2 lg:mt-0 text-right lg:text-left lg:justify-end text-sm items-center">
              <button
                onClick={() => setIsLoginModalOpen(true)}
                className="block mt-4 lg:inline-block cursor-pointer tracking-wide font-medium lg:mt-0 lg:mr-4 mb-2 lg:mb-0"
              >
                Log In
              </button>
            </div>
          )}
        </div>
      </nav>
      <LogInModal isModalOpen={isLoginModalOpen} closeModal={() => setIsLoginModalOpen(false)} />
    </>
  );
};

export default Navbar;
