import React from 'react';
import { Link } from '@inertiajs/inertia-react';

const Footer = () => {
  return (
    <footer className="mt-4 px-2">
      <div className="mx-auto px-3 md:px-0">
        <div className="grid sm:grid-cols-12 gap-8 py-8 sm:px-6 border-t border-gray-200">
          <div className="sm:col-span-12 md:col-span-4 lg:col-span-3">
            <div className="flex items-center flex-no-shrink mr-6 text-xl">
              <span className="font-semibold tracking-wide">
                Intro
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-700">
                  Send
                </span>
              </span>
            </div>
          </div>
          <div className="lg:col-span-4 sm:hidden lg:block"></div>
          <div className="sm:col-span-6 md:col-span-4 lg:col-span-2">
            <div className="text-gray-800 font-medium mb-2">Resources</div>
            <ul className="text-sm">
              <li className="mb-2">
                <Link
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  href="/"
                >
                  Home
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  href="/#about"
                >
                  About
                </Link>
              </li>
              {/* <li className="mb-2">
                <Link
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  href="/blog"
                >
                  Blog
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="sm:col-span-6 md:col-span-4 lg:col-span-2">
            <div className="text-gray-800 font-medium mb-2">Company</div>
            <ul className="text-sm">
              <li className="mb-2">
                <a
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  href="mailto:info@introsend.com"
                >
                  Contact Us
                </a>
              </li>
              <li className="mb-2">
                <Link
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  href="/guidelines"
                >
                  Community Guidelines
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  href="/terms"
                >
                  Terms and Conditions
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  href="/privacy"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-200 mx-auto text-xs text-gray-600">
          <div className="py-6 md:px-3">
            © 2021 - {new Date().getFullYear()} IntroSend. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
